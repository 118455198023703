<template>
  <div class="right-button-div">
    <div class="free-experience-div" @click="toDownload">
      <div class="button-text">资料下载</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "downloadBtn",
  data() {
    return {

    }
  },
  methods: {
    toDownload() {
      this.$router.push({
        path: '/giEdifice/downloadCenter',
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .right-button-div {
    text-align: center;
    z-index: 12;
    width: 80px;
    height: 50px;
    background: #196CF6;
    border-radius: 4px;
    position: fixed;
    bottom: 130px;
    right: 19px;
    padding-top: 13px;
    box-sizing: border-box;

    .free-experience-div {
      cursor: pointer;
    }

    .button-text {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular;
      line-height: 14px;
      color: #FFFFFF;
      margin-top: 5px;
    }

  }
  @media screen and (max-width: 1366px) {
    .right-button-div {
      text-align: center;
      z-index: 12;
      width: 80px;
      height: 50px;
      background: #196CF6;
      border-radius: 4px;
      position: fixed;
      bottom: 275px;
      right: 19px;
      padding-top: 13px;
      box-sizing: border-box;

      .free-experience-div {
        cursor: pointer;
      }

      .button-text {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        line-height: 14px;
        color: #FFFFFF;
        margin-top: 5px;
      }

    }
  }
</style>
