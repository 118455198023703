<!--办展流程-->
<template>
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col >
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <div>
        <div class="contentDiv" style="padding-right: 15px">
          <el-row v-for="(item, index) in dataList" :key="index" class="flexContainer">
            <div :class="index == 0 ? 'contentClass' : 'contentClassOther'">
              <div v-show="index % 2 == 0">
                <div class="flexAlignItemCenter titleContainer">
                  <div class="titleClass">{{item.processName}}</div>
                </div>
                <div>
                  <div class="w-e-text-container textAuto">
                    <div v-html="item.processIntroduct" data-slate-editor></div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="index == 0 ? 'flowClass' : 'flowClassOther'">
              <div class="circleDashed">
                <div class="lightBlueCircle" v-show="index % 2 == 0"></div>
                <div class="blueDashedLine" v-show="index % 2 == 0"></div>
                <div class="blueCircle flexACenterJCenter" :class="index % 2 != 0 ? 'circleMarginLeft' : 'circleMarginRight'">
                  <span class="numberClass">{{index + 1}}</span>
                </div>
                <div class="blueDashedLine" v-show="index % 2 != 0"></div>
                <div class="lightBlueCircle" v-show="index % 2 != 0"></div>
              </div>
              <div class="blueSolidLine" v-show="index !== dataList.length - 1"></div>
            </div>
            <div class="contentClass" style="padding-left: 15px; margin-top: -90px;">
              <div v-show="index % 2 != 0">
                <div class="flexAlignItemCenter titleContainer">
                  <div class="titleClass">{{item.processName}}</div>
                </div>
                <div>
                  <div class="w-e-text-container textAuto">
                    <div v-html="item.processIntroduct" data-slate-editor></div>
                  </div>
                </div>
              </div>
            </div>
          </el-row>
        </div>
      </div>
    </el-row>

    <!--资料下载按钮-->
    <download-btn></download-btn>

  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import downloadBtn from '@/views/giEdifice/downloadBtn.vue'
import axios from 'axios'

export default {
  name: 'process',
  components: { topPathList, downloadBtn },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '办展流程',
          path: '/giEdifice/process'
        },
      ],
      dataList: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/process/list",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let obj = res.data.data;
          this.dataList = obj || []
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.contentDiv {
  background: white;
  margin-bottom: 40px;
  padding: 25px 50px 50px;
}

.contentClass {
  width: calc(50% - 100px);
}

.contentClassOther {
  width: calc(50% - 100px);
  margin-top: -90px;
}

.flowClass {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .circleDashed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .blueCircle {
    width: 40px;
    height: 36px;
    background: #007AFF;
    border: 4px solid #C1D9FF;
    border-radius: 50px;
  }
  .blueSolidLine {
    width: 1px;
    height: calc(100% - 100px);
    border-left: 2px solid #007AFF;
  }
  .blueDashedLine {
    width: calc(50% - 22px);
    height: 0;
    box-shadow: inset 0px 1px 7px 1px #C1D9FF;;
    border-bottom: 3px dashed #C1D9FF;;
  }
  .numberClass {
    font-size: 18px;
    font-family: Arial-BoldMT, Arial;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 20px;
  }
}

.flowClassOther {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -100px;

  .circleDashed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .blueCircle {
    width: 40px;
    height: 36px;
    background: #007AFF;
    border: 4px solid #C1D9FF;
    border-radius: 50px;
  }
  .blueSolidLine {
    width: 1px;
    height: calc(100% - 100px);
    border-left: 2px solid #007AFF;
  }
  .blueDashedLine {
    width: calc(50% - 22px);
    height: 0;
    box-shadow: inset 0px 1px 7px 1px #C1D9FF;;
    border-bottom: 3px dashed #C1D9FF;;
  }
  .numberClass {
    font-size: 18px;
    font-family: Arial-BoldMT, Arial;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 20px;
  }
}
.lightBlueCircle {
  width: 10px;
  height: 10px;
  background: #C1D9FF;
  border-radius: 10px;
}
.circleMarginLeft {
  margin-left: calc(50% - 22px);
}
.circleMarginRight {
  margin-right: calc(50% - 22px);
}

.titleContainer {
  margin-bottom: 20px;

  .titleClass {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #002964;
    line-height: 27px;
  }

}

.textAuto {
  overflow: auto;
  height: 200px;
  cursor: pointer;
}
::-webkit-scrollbar {  /*隐藏滚动条*/
  display: none;
}
</style>
